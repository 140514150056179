html {
  height: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  /* font-weight: 300; */
  letter-spacing: 1px;
  padding-top: 0;
  background-color: #21252e;
  height: 100%;
}

strong {
  /* font-weight: normal; */
}

button {
  letter-spacing: 1px;
}

.sw-blur-bg {
  background-image: url('/assets/images/prodlandingBG.png');
  background-position: left top;
  background-size: cover;
}

.sw-a-link {
  color: #8d939c;
  text-decoration: none;
  cursor: pointer;
}

.sw-a-link-underline {
  text-decoration: underline;
}

.sw-a-link:hover,
.sw-a-link:hover:active,
.sw-a-link:hover:focus {
  color: white;
}

.sw-a-link:active,
.sw-a-link:focus {
  box-shadow: none;
  outline: none;
  color: #bac2cc;
}

.mrgnbtm10 {
  margin-bottom: 10px;
}

.mrgnbtm-1 {
  margin-bottom: -1px;
}

.radiustop-rgt-lft0 {
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
  -khtml-border-radius: 0px 0px 4px 4px;
  border-radius: 0px 0px 4px 4px;
}

.radiusbtm-rgt-lft0 {
  -moz-border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
  -khtml-border-radius: 4px 4px 0px 0px;
  border-radius: 4px 4px 0px 0px;
}

.sws-logo-title {
  height: 81px;
  margin: 10px auto;
  display: block;
}

.swtoolbar .sws-logo {
  height: 36px;
}

.margin-side-auto {
  margin-left: auto;
  margin-right: auto;
}

.width-360 {
  width: 360px;
}

.v-center-30 {
  position: relative;
  top: 30%;
  transform: translateY(-30%);
}

.sw-navbar {
  border: none;
  height: 50px;
  min-height: 50px;
  background-color: #1c1d22;
}

.sw-sidebar {
  display: none;
}

.sw-disable,
.sw-disable a {
  pointer-events: none !important;
  opacity: .95;
  color: rgb(71, 75, 82);
  border-color: rgb(71, 75, 82);
  filter: alpha(opacity=95);
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  .sw-sidebar {
    background-color: #2b2f38;
    border-right: 1px solid #1c1d22;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 52px;
    width: 220px;
    height: calc(100% - 54px);
    z-index: 1000;
  }
}

.sw-sidebar-left {
  left: 0;
}

.sw-sidebar-lg {
  width: 280px;
}

.sw-sidebar-production-list {
  width: 200px;
}

.sw-sidebar-settings-list {
  background-color: #21252E;
  width: 240px;
  border: none;
}

.sw-sidebar-edit {
  width: 280px;
}

.sw-sidebar>.sw-navbar {
  width: 100%;
}

.sw-sidebar>.sw-navbar-top {
  position: absolute;
  top: 0;
  height: auto;
}

.sw-sidebar>.sw-navbar-bottom {
  position: absolute;
  bottom: 0;
  height: auto;
}

.sw-sidebar>.scrollcontainer {
  width: 100%;
  height: calc(100% - 110px);
  position: absolute;
  top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 5px 0;
}

.sw-sidebar-nofooter>.scrollcontainer {
  height: calc(100% - 60px);
}

.sw-maincontentarea {
  position: fixed;
  top: 52px;
  left: 222px;
  height: calc(100% - 54px);
  width: calc(100% - 222px);
  overflow: hidden;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0);
}

.swtoolbar .sw-text-lg {
  font-size: 20px;
  line-height: 30px;
  vertical-align: middle;
  display: inline-block;
  height: 30px;
}

.sw-settingsdetailcontent .sw-text-lg {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 30px;
  vertical-align: middle;
  display: inline-block;
  height: 30px;
}

.container-fluid>.sidebar {
  position: fixed;
  top: 60px;
  width: 220px;
  margin-left: 5px
}

.padding_0 {
  padding: 0 !important;
}

a.linkWhite {
  color: #fff;
}

.container-fluid>.content_outer {
  margin: 0 210px
}

.left {
  float: left
}

.right {
  float: right
}

.mrgnRight0 {
  margin-right: 0 !important;
}

.mrgnLeft0 {
  margin-left: 0 !important;
}

.mrgnLeftRight0 {
  margin: 0 !important;
}

.mrgnLeft5 {
  margin-left: 5px !important;
}

.mrgnLeft15 {
  margin-left: 15px !important;
}

.mrgnRight5 {
  margin-right: 5px !important;
}



/*=============Right and left panel========*/

.navbar>.container-fluid .sideBarTop-logo .navbar-header {
  width: 100%;
}

.navbar>.container-fluid .sideBarTop-logo .navbar-header .navbar-brand {
  margin-left: 15px;
}

.nav.listing-items>li>a {
  color: firebrick;
  height: 200px
}

.posi-relativeOnly,
.left-siderbarDiv,
.middContent-nav,
.left-pageBtmLink {
  width: 220px;
}

.posi-relativeOnly {
  float: left;
  margin-left: -15px
}

.left-siderbarDiv {
  height: 100%;
  min-height: 100%;
  position: fixed;
  margin-left: 10px;
}

.middContent-nav {
  bottom: 44px;
  display: block;
  position: fixed;
  top: 88px;
  z-index: 1000
}

.left-pageBtmLink {
  background-color: #e9e9e9;
  bottom: 0;
  position: fixed;
  padding: 12px 15px
}

.pro-name-lft {
  padding: 0 15px;
  display: inline-block
}

.sw-switch.switch {
  border-color: #60656D;
  background-color: transparent;
  width: 60px;
}

.sw-switch.switch.checked {
  border-color: #60656D;
  background-color: #60656D;
}

.sw-switch.switch small {
  border-color: #60656D;
  background-color: #BAC2CC;
}

.sw-switch.switch.checked small {
  border-color: #60656D;
  background-color: white;
  left: 30px;
}

.sw-switch.switch:hover {
  border-color: #BAC2CC;
}

.swa .slider.slider-horizontal {
  width: 100%;
}

.swa .slider-handle {
  background-color: dimgray;
  background-image: none;
}

.swlogo {
  width: 114px;
  height: 36px;
}

.sidebar-hint-text {
  color: #60656D;
}

.swtoolbar {
  height: 50px;
  line-height: 50px;
  cursor: default;
  width: 100%;
  background-color: #1c1d22;
}

.swtoolbar-group {
  padding: 0 8px;
  display: inline-block;
}

.swtoolbar-group-leftmost {
  padding-left: 0;
}

.swtoolbar-group-rightmost {
  padding-right: 0;
}

.swtoolbar .btn {
  font-weight: 300;
}

.swtoolbar .image {
  vertical-align: middle;
  padding: 0 4px;
}

.swtoolbar .button {
  vertical-align: middle;
  padding: 1px 4px;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: none;
}

.swtoolbar .selected {
  border-bottom: 3px solid #555;
}

.swtoolbar .enabled {
  opacity: 1.0;
  pointer-events: auto;
}

.swtoolbar .text {
  vertical-align: middle;
  padding: 0 4px;
  font-size: 20px;
  font-family: 'Verdana';
  color: #eeeeee;
  text-shadow: 0 0 1px black;
  cursor: default;
}

.swtoolbar .textcenter {
  align-content: center;
  vertical-align: middle;
  padding: 0 4px;
  font-size: 20px;
  font-family: 'Verdana';
  color: #eeeeee;
  text-shadow: 0 0 1px black;
  cursor: default;
}

.swtoolbar .spacer16 {
  vertical-align: middle;
  padding: 0 8px;
  pointer-events: none;
  cursor: default;
}

.btn-clear:hover {
  color: #f82c5b
}

.btn-clear {
  background-color: transparent;
  color: white;
}

.btn-clear[disabled],
.btn-clear.disabled {
  opacity: 0.35;
}

.btn-clear.clicked {
  color: springgreen;
}


.prolistOuterDiv {
  background-color: #2B2F38
}

.sidebar {
  background-color: #2B2F38
}

.sw-settings-option-list {}

.sw-settings-option-list>.item {
  height: 40px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;
}

.sw-settings-option-list>.item:after {
  transition: all 0.3s ease 0s;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(95, 100, 108, 1), rgba(95, 100, 108, 0));
}

.sw-settings-option-list>.item:hover {
  padding-left: 2px;
}

.sw-settings-option-list>.item:hover:after {
  /*border-bottom: 1px solid #f82c5b;*/
  background-image: linear-gradient(to right, rgba(249, 44, 91, 1), rgba(249, 44, 91, 0));
}

.sw-settings-option-list>.item>.icon {
  font-size: 16px;
  line-height: 40px;
  float: right;
}

.sw-settings-option-list>.item>.title {
  color: #60656D;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
}

.sw-settings-option-list>.item-selected>.icon {
  color: #bac2cc;
}

.sw-settings-option-list>.item-selected>.title {
  color: #bac2cc;
}

.sw-settingsdetailcontent {
  left: 245px;
  height: calc(100% - 54px);
  width: calc(100% - 245px);
  background-color: #21252E
}

.sw-settingsdetailcontent>.scrollcontainer {
  top: 52px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 2px);
}

.settingsDetailPanel {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.settingsDetailPanel .panel-body {
  background-color: #2B2F38;
  border: none;
}

.settingsDetailPanel .panel-footer {
  background-color: transparent;
  border: none;
}

.settingsDetailPanel label {
  text-align: left !important;
  padding-bottom: 7px;
}

.settingsDetailPanel .form-group {
  border-bottom: solid 1px #f2f2f2;
  padding-bottom: 5px;
  margin-bottom: 6px;
}

.settingsDetailPanel .form-group label {
  font-weight: normal
}

.settingsDetailPanel .form-group {
  border-bottom: none
}

.settingsDetailPanel .form-group label {
  color: #BAC2CC;
  font-weight: 300;
  letter-spacing: 1px;
}

.settingsDetailPanel .form-group .form-control {
  border: none;
  background-color: #4F535B;
  color: #BAC2CC;
  letter-spacing: 1px;
}

.settingsDetailPanel .form-group .form-control:focus {
  border: 1px solid #BAC2CC;
  color: #BAC2CC;
  outline: none;
  box-shadow: none;
}

.settingsDetailPanel .form-group .form-control::selection {
  color: black;
  background-color: #BAC2CC;
}

.settingsDetailPanel .form-group .form-control::-moz-selection {
  color: black;
  background-color: #BAC2CC;
}

.lft-lbl-vertical {
  vertical-align: super
}

.sw-btn-xsm {
  font-size: 10px;
  border-radius: 2px;
  padding: 4px 6px;
}

.sw-btn-toolbar {
  background-color: transparent;
  border: none;
  color: white;
  transition: all 0.3s ease 0s;
}

.sw-btn-toolbar:focus,
.sw-btn-toolbar:active:focus,
.sw-btn-toolbar.active:focus,
.sw-btn-toolbar.focus,
.sw-btn-toolbar:active.focus,
.sw-btn-toolbar.active.focus {
  outline: none;
  box-shadow: none;
}

.sw-btn-toolbar:hover,
.sw-btn-toolbar:focus:hover {
  color: #f82c5b;
}

.sw-btn-toolbar:focus,
.sw-btn-toolbar.focus {
  color: white;
  outline: none;
  box-shadow: none;
}

.sw-btn-toolbar:active,
.sw-btn-toolbar.active {
  outline: none;
  box-shadow: none;
}

.sw-btn-toolbar.disabled,
.sw-btn-toolbar[disabled] {
  color: #60656D;
  opacity: 1.0;
}

.sw-btn-toolbar .icon-sw-lg {
  font-size: 1.5em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.sw-active .icon-sw {
  color: #f82c5b;
  padding-bottom: 6px;
  border-bottom: 1px solid #f82c5b;
}

.sw-btn-toolbar.disabled .icon-sw,
.sw-btn-toolbar[disabled] .icon-sw {
  color: #60656D;
  opacity: 1.0;
  padding-bottom: 0;
  border-bottom: none;
}

.swtoolbar-group>.sw-btn-toolbar {
  padding-left: 6px;
  padding-right: 6px;
}

.swtoolbar-group>.sw-btn-toolbar+.sw-btn-toolbar {
  margin: 2px;
}

.sw-btn-default {
  background-color: transparent;
  border-color: #f82c5b;
  color: #f82c5b;
  transition: all 0.3s ease 0s;
}

.sw-btn-default:hover {
  color: #fff;
  border-color: white;
  outline: none;
}

.sw-btn-default:focus,
.sw-btn-default.focus {
  color: #fff;
  border-color: white;
  outline: none;
  text-decoration: underline;
}

.sw-btn-default:active,
.sw-btn-default.active {
  outline: none;
  box-shadow: none;
}

.sw-btn-default.disabled,
.sw-btn-default[disabled],
fieldset[disabled] .sw-btn-default {
  border-color: #60656D;
  color: #60656D;
}

.sw-btn-secondary {
  background-color: transparent;
  border-color: #8d939c;
  color: #8d939c;
  transition: all 0.3s ease 0s;
}

.sw-btn-secondary:focus,
.sw-btn-secondary:active:focus,
.sw-btn-secondary.active:focus,
.sw-btn-secondary.focus,
.sw-btn-secondary:active.focus,
.sw-btn-secondary.active.focus {
  outline: none;
  box-shadow: none;
}

.sw-btn-secondary:hover {
  color: #fff;
  border-color: white;
  outline: none;
}

.sw-btn-secondary:focus,
.sw-btn-secondary.focus {
  color: #fff;
  border-color: white;
  outline: none;
  text-decoration: underline;
}

.sw-btn-secondary:active,
.sw-btn-secondary.active {
  outline: none;
  box-shadow: none;
}

.sw-btn-secondary.disabled,
.sw-btn-secondary[disabled],
fieldset[disabled] .sw-btn-secondary {
  border-color: #60656D;
  color: #60656D;
}

.sw-btn-secondary-label,
.sw-btn-secondary-label:hover,
.sw-btn-secondary-label:focus {
  background-color: transparent;
  border-color: #8d939c;
  color: #8d939c;
  transition: none;
}

.sw-btn-secondary-label.disabled,
.sw-btn-secondary-label[disabled],
.sw-btn-secondary-label-disabled[disabled],
.sw-btn-secondary-label-disabled:hover {
  background-color: transparent;
  border-color: #60656D;
  color: #60656D;
  opacity: 1.0;
}

.sw-btn-danger {
  background-color: transparent;
  border-color: #f82c5b;
  color: #f82c5b;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.sw-btn-danger:focus,
.sw-btn-danger:active:focus,
.sw-btn-danger.active:focus,
.sw-btn-danger.focus,
.sw-btn-danger:active.focus,
.sw-btn-danger.active.focus {
  outline: none;
  box-shadow: none;
}

.sw-btn-danger:hover {
  color: #fff;
  background-color: #f82c5b;
  outline: none;
}

.sw-btn-danger:focus,
.sw-btn-danger.focus {
  color: #fff;
  background-color: #f82c5b;
  outline: none;
  text-decoration: underline;
}

.sw-btn-danger:active,
.sw-btn-danger.active {
  outline: none;
  box-shadow: none;
}

.sw-btn-danger.disabled,
.sw-btn-danger[disabled],
fieldset[disabled] .sw-btn-danger {
  border-color: #60656D;
  color: #60656D;
}

.sw-btn-danger-solid {
  background-color: #f82c5b;
  border-color: #f82c5b;
  color: white;
  transition: all 0.3s ease 0s;
}

.sw-btn-danger-solid:focus,
.sw-btn-danger-solid:active:focus,
.sw-btn-danger-solid.active:focus,
.sw-btn-danger-solid.focus,
.sw-btn-danger-solid:active.focus,
.sw-btn-danger-solid.active.focus {
  outline: none;
  box-shadow: none;
}

.sw-btn-danger-solid:hover {
  background-color: #f82c5b;
  color: white;
  border-color: #bac2cc;
  outline: none;
}

.sw-btn-danger-solid:focus {
  background-color: #f82c5b;
  color: white;
  border-color: white;
  outline: none;
  text-decoration: underline;
}

.sw-btn-danger-solid.disabled,
.sw-btn-danger-solid[disabled],
fieldset[disabled] .sw-btn-danger-solid {
  background-color: #f82c5b;
  border-color: #f82c5b;
  color: white;
  opacity: 0.3;
}

.sw-btn-radio {
  background-color: transparent;
  border-color: #60656D;
  color: #BAC2CC;
  transition: all 0.3s ease 0s;
}

.sw-btn-radio:focus,
.sw-btn-radio:active:focus,
.sw-btn-radio.active:focus,
.sw-btn-radio.focus,
.sw-btn-radio:active.focus,
.sw-btn-radio.active.focus {
  outline: none;
  box-shadow: none;
}

.sw-btn-radio.checked {
  color: white;
  background-color: #60656D;
}

.sw-btn-radio:hover {
  color: #BAC2CC;
  border-color: #BAC2CC;
  outline: none;
}

.sw-btn-radio.checked:hover {
  color: white;
}

.sw-btn-radio:focus,
.sw-btn-radio.focus {
  color: white;
  border-color: #BAC2CC;
  outline: none;
  text-decoration: underline;
}

.sw-btn-radio.disabled,
.sw-btn-radio[disabled],
fieldset[disabled] .sw-btn-radio {
  border-color: #60656D;
  color: #60656D;
}



/*.sw-btn-radio.checked:hover {
  color: #BAC2CC;
}*/

.sw-btn-radio-disabled,
.sw-btn-radio-disabled[disabled],
.sw-btn-radio-disabled:hover {
  background-color: transparent;
  border-color: #60656D;
  color: #60656D;
  transition: all 0.3s ease 0s;
  opacity: 0.5;
}

.no-padd {
  padding: 0
}

.navbar-brandLogo {
  height: 50px
}

.pad5 {
  padding: 5px;
}

.pad-side5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pad-updn5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sw-ionicon {
  letter-spacing: normal;
  font-weight: normal;
}

.sw-ionicon-fw {
  width: 1.28571429em;
  text-align: center;
  display: inline-block;
}

.sw-ionicon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.sw-ionicon-2x {
  font-size: 2em;
}

.sw-truncatetext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sw-truncatetext-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sw-color-white,
.swa-color-white {
  color: #ffffff;
}

.sw-color-lightlightgray,
.swa-color-whitelightgray {
  color: #DDE1E6;
}

.sw-color-lightgray,
.swa-color-lightgray {
  color: #bac2cc;
}

.sw-color-mediumlightgray,
.swa-color-lightgraygray {
  color: #8d939c;
}

.sw-color-gray,
.swa-color-gray {
  color: #60656d;
}

.sw-color-graydarkgray,
.swa-color-graydarkgray {
  color: #4a4e56;
}

.sw-color-darkergray,
.swa-color-darkgray {
  color: #33363E;
}

.sw-color-dark,
.swa-color-dark {
  color: #151616;
}

.sw-color-red,
.swa-color-accentred {
  color: #F82C5B;
}

.sw-color-grid {
  color: #C5C7C9;
}

.sw-color-bg-toolbar {
  background-color: #1c1d22;
}

.sw-color-bg-sidebar {
  background-color: #2b2f38;
}

.sw-color-bg-page {
  background-color: #21252e;
}

.sw-color-bg-form {
  background-color: #4f535b;
}

.sw-color-bg-white {
  background-color: white;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-padd {
  padding: 0
}

.navbar-brandLogo {
  height: 50px
}

.swtoolbar button+button {
  margin-left: 5px;
}

.sw-login-form .form-control {
  border-color: #747984;
  background-color: #4F535B;
  color: #ffffff;
  padding: 10px;
  height: auto;
  font-size: 16px;
  position: relative;
  ;
}

.sw-login-form .form-control:focus {
  border-color: #BAC2CC;
  outline: none;
  box-shadow: none;
  z-index: 2;
}

.sw-login-form .form-control::-moz-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.sw-login-form .form-control:-ms-input-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.sw-login-form .form-control::-webkit-input-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.sw-popover-title {
  height: 40px;
  color: #BAC2CC;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  font-weight: 300;
  padding: 5px;
}

.sw-popover-title span {
  padding: 0 5px;
}

.sw-popover-content {
  min-width: 120px;
  max-height: 50%;
  background-color: #1C1D22;
  margin: 5px;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  padding: 2px;
  font-weight: 300;
}

.sw-popover-hint-item {
  height: 40px;
  line-height: 40px;
  width: 100%;
  color: #60656D;
}

.sw-popover-menu {
  min-width: 120px;
  max-height: 50%;
  background-color: #1C1D22;
  margin: 5px;
  border-radius: 4px;
  display: block;
  list-style: none;
  font-size: 14px;
  padding: 2px;
  font-weight: 300;
}

.sw-popover-title+.sw-popover-menu {
  margin-top: 0;
}

.sw-popover-menu.pull-right {
  right: 0;
  left: auto;
}

.sw-popover-menu .divider {
  height: 1px;
  margin: 2px 0;
  overflow: hidden;
  background-color: #2B2F38;
}

.sw-popover-menu>li>a {
  display: block;
  padding: 5px 10px;
  clear: both;
  color: #BAC2CC;
  white-space: nowrap;
  min-height: 24px;
  line-height: 24px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #2B2F38;
}

.sw-popover-menu>li+li {
  margin-top: 1px;
}

.sw-popover-menu>li>a:hover,
.sw-popover-menu>li>a:focus {
  text-decoration: none;
  color: white;
  background-color: #2B2F38;
  box-shadow: 0 0 1px 0 #BAC2CC inset;
}

.sw-popover-menu>li>a[disabled] {
  color: #60656D;
  pointer-events: none;
  cursor: not-allowed;
}

.sw-popover-menu>.active>a,
.sw-popover-menu>.active>a:hover,
.sw-popover-menu>.active>a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}

.sw-popover-menu>.disabled>a,
.sw-popover-menu>.disabled>a:hover,
.sw-popover-menu>.disabled>a:focus {
  color: #777777;
}

.sw-popover-menu>.disabled>a:hover,
.sw-popover-menu>.disabled>a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed;
}

.sw-popover-content>.swtoolbar {
  height: 40px;
  line-height: 40px;
}

.sw-popover-content>.swtoolbar .btn-sm {
  font-size: 10px;
  padding: 4px 6px;
}

.sw-modal-backdrop.in {
  opacity: 0.7;
}

.sw-modal400 {
  min-width: 400px;
}

.sw-modal .modal-dialog {
  margin: 60px auto;
}

.sw-modal .modal-content {
  background-color: #2B2F38;
  border: none;
  border-radius: 0;
}

.sw-modal .modal-header {
  background-color: #1C1D22;
  border-radius: 0;
  color: #BAC2CC;
  border: none;
  padding: 10px 15px;
}

.sw-modal .modal-title {
  color: #F82C5B;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.sw-modal .modal-body {
  color: #BAC2CC;
}

.sw-modal .modal-body label {
  font-weight: 300;
}

.sw-modal .modal-footer {
  padding-top: 0;
  border: none;
}

.sw-modal .form-control {
  border-color: #4F535B;
  background-color: transparent;
  color: #BAC2CC;
}

.sw-modal .form-control:focus {
  border-color: #BAC2CC;
  background-color: transparent;
  color: #BAC2CC;
  outline: none;
  box-shadow: none;
}

.sw-modal .form-control::-moz-placeholder {
  color: #60656D;
  opacity: 1;
}

.sw-modal .form-control:-ms-input-placeholder {
  color: #60656D;
}

.sw-modal .form-control::-webkit-input-placeholder {
  color: #60656D;
}

.sw-modal fieldset[disabled] .form-control {
  background-color: transparent;
  border-color: #4F535B;
  color: #4F535B;
}

.sw-modal .input-group-addon {
  background-color: #BAC2CC;
  border-color: #BAC2CC;
  color: #151616;
}

.sw-block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: black;
}

.sw-block-ui-gear {
  margin: auto;
  text-align: center;
  color: white;
  font-size: 20px;
}

.sw-block-ui-message {
  display: inline-block;
  text-align: center;
  background-color: rgba(43, 47, 56, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.sw-block-ui-message-text {
  padding: 10px;
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.settings-list-container {
  padding: 0 10px;
  background-color: #2B2F38;
}

.settingsDetailPanel .form-control:focus {
  border: 1px solid #BAC2CC;
  color: #BAC2CC;
  outline: none;
  box-shadow: none;
}

.settingsDetailPanel .form-control::selection {
  color: black;
  background-color: #BAC2CC;
}

.settingsDetailPanel .form-control::-moz-selection {
  color: black;
  background-color: #BAC2CC;
}

.sw-form-container .form-control {
  border: none;
  background-color: #4F535B;
  color: #BAC2CC;
  letter-spacing: 1px;
}

.sw-form-container .form-control:focus {
  border: 1px solid #BAC2CC;
  color: #BAC2CC;
  outline: none;
  box-shadow: none;
}

.sw-form-container .form-control::selection {
  color: black;
  background-color: #BAC2CC;
}

.sw-form-container .form-control::-moz-selection {
  color: black;
  background-color: #BAC2CC;
}


.sw-btn-toolbar.active .sw-ionicon-2x {
  color: #f82c5b;
  padding-bottom: 3px;
  border-bottom: 1px solid #f82c5b;
}

.sw-gray-panel {
  background-color: #2b2f38;
  padding: 40px 0 20px;
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
  /*overflow: hidden;*/
}

.sw-gray-panel .sw-gray-form,
.sw-gray-content {
  padding: 15px;
  margin: 0 auto;
}

.sw-gray-form .form-control {
  border-color: #747984;
  background-color: #4F535B !important;
  color: #ffffff;
  padding: 10px;
  height: auto;
  font-size: 16px;
  position: relative;
}



/*.sw-gray-form .form-control:-webkit-autofill {
  background-color: #4F535B !important;
}*/

.sw-gray-form .form-control:-webkit-autofill,
.sw-gray-form .form-control:focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #4F535B inset;
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff;
}

.sw-gray-form .form-control:focus {
  border-color: #BAC2CC;
  outline: none;
  box-shadow: none;
  z-index: 2;
}

.sw-gray-form .form-control::-moz-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.sw-gray-form .form-control:-ms-input-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.sw-gray-form .form-control::-webkit-input-placeholder {
  color: rgba(186, 194, 204, 0.6);
}

.div-topPad {
  padding-top: 60px;
}

.modal-480 {
  width: 480px;
}

.panel-table {
  color: #2b2f38;
  border: none;
  border: 1px solid #60656D;
  width: 100%;
}

.panel-table>thead {
  background-color: #60656D;
  /*height: 40px;*/
}

.panel-table>tbody tr {
  background-color: #2b2f38;
}

.panel-table th,
.panel-table td {
  border: none;
  padding: 0 8px;
  font-weight: 300;
  /*height: 40px;*/
}

.panel-table thead>tr>th,
.panel-table thead>tr>td {
  color: #bac2cc;
}

.panel-table tbody>tr>th {
  color: #bac2cc;
}

.panel-table tbody>tr>td {
  color: #8d939c;
  border: none;
  /*border: 1px solid #1c1d22;*/
}

.panel-table>tfoot {
  background-color: #2b2f38;
  border-top: 1px solid #1c1d22;
}

.panel-table>tfoot>tr>td {
  padding: 15px;
  font-weight: 300;
  height: 40px;
}

.panel-table-striped>tbody tr:nth-of-type(odd) {
  background-color: #2b2f38;
}

.panel-table-striped>tbody tr:nth-of-type(even) {
  background-color: #24262D;
  background-color: rgba(43, 47, 56, 0.5)
}

.panel-table-bordered {
  border: 1px solid #8d939c;
}

.panel-table-bordered>thead>tr>th {
  border: 1px solid #8d939c;
}

.panel-table-bordered>tbody>tr>td {
  border: 1px solid #8d939c;
}

.sw-panel-container .panel {
  background-color: #2b2f38;
  border-radius: 0;
  border: none;
}

.sw-panel-container .panel-default>.panel-heading {
  background-color: #1c1d22;
  border: none;
  color: #f82c5b;
}

.sw-panel-container .panel>.panel-body+.panel-table {
  border: none;
}

.sw-text-sm {
  font-size: 0.8em;
  line-height: 0.96em;
  vertical-align: middle;
}

.sw-text-md {
  font-size: 14px;
  line-height: 16.8px;
  vertical-align: middle;
}

.sw-text-lg {
  font-size: 17px;
  line-height: 20.4px;
  vertical-align: middle;
}

.sw-text-xlg {
  font-size: 20px;
  line-height: 24px;
  vertical-align: middle;
}

.sw-text-xxlg {
  font-size: 24px;
  line-height: 28.8px;
  vertical-align: middle;
}

.dropdown-menu {
  background-color: #4F535B;
  /*box-shadow: 0 3px 7px black;*/
  border-color: black;
  cursor: pointer;
  padding: 2px;
}

.dropdown-menu.inner {
  background-color: #1C1D22;
  margin: 5px;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  padding: 2px;
  font-weight: 300;
}

.dropdown-menu>li>a {
  display: block;
  padding: 5px 10px;
  clear: both;
  color: #BAC2CC;
  white-space: nowrap;
  min-height: 36px;
  /*line-height: 24px;*/
  border-radius: 2px;
  cursor: pointer;
  background-color: #2B2F38;
}

.dropdown-menu>li+li {
  margin-top: 1px;
}

.dropdown-menu>li>a:hover {
  text-decoration: none;
  color: white;
  background-color: #2B2F38;
  box-shadow: 0 0 1px 0 #BAC2CC inset;
}

.dropdown-menu>li>a:focus {
  text-decoration: none;
  color: white;
  background-color: #2B2F38;
  box-shadow: none;
  outline: none;
}

.dropdown-menu>li>a[disabled] {
  color: #60656D;
  pointer-events: none;
  cursor: not-allowed;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.panel-stats table tr td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.hint-icon-message {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #2b2f38;
  border: 1px solid #4a4e56;
  border-radius: 2px;
}

.hint-icon-message>.icon {
  padding: 0 2px;
  text-align: center;
  color: #8d939c;
  background-color: #4a4e56;
  display: flex;
  align-items: center;
}

.hint-icon-message>.text {
  color: #8d939c;
  font-size: 14px;
  flex-grow: 2;
  padding: 2px 4px;
}

.hint-icon-message-autow {
  display: inline-flex;
}

.swa-text-danger {
  color: #f82c5b;
}

.list-style-none {
  list-style: none;
}
.font-bold {
  font-weight: 600;
}

.mti-details-header {
  font-weight: 300;
  font-size: 15px;
  margin: 4px 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-body-overflow {
  max-height: calc(100vh - 185px);
  overflow: auto;
}

.sw-btn-secondary-white-bg:hover {
  border-color: #8d939c;
  background-color: #8d939c;
  color: white;
}

.manage-account-dropdown .dropdown-menu .account-list{
 max-height: 300px;
 overflow-y: auto;
}

.manage-account-dropdown .search-wrapper{
  position: relative;
}

.manage-account-dropdown .search-wrapper input.form-control{
  padding-right: 35px;
  border: 1px solid #21242e !important;
}

.manage-account-dropdown .search-wrapper .search-text-remove{
  position: absolute;
  right: 0;
  top: 2px;
  min-height: auto;
  background: none;
}
.manage-account-dropdown .search-wrapper .search-text-remove:hover{
  text-decoration: none;
  color: white;
  box-shadow: none;
  background: none;
}
