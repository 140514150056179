@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?-mrwgf8');
  src: url('/assets/fonts/icomoon.eot?#iefix-mrwgf8') format('embedded-opentype'), url('/assets/fonts/icomoon.ttf?-mrwgf8') format('truetype'), url('/assets/fonts/icomoon.woff?-mrwgf8') format('woff'), url('/assets/fonts/icomoon.svg?-mrwgf8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}*/

.icon-sw {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  display: inline-block;
}
.icon-sw-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-sw-2x {
  font-size: 2em;
  line-height: 2em;
}
.icon-sw-3x {
  font-size: 3em;
}
.icon-sw-4x {
  font-size: 4em;
}
.icon-sw-5x {
  font-size: 5em;
}
.icon-sw-fw {
  width: 1.28571429em;
  text-align: center;
}
.icon-sw-spin-2x {
  -webkit-animation: icon-sw-spin-2x 2s infinite linear;
  animation: icon-sw-spin-2x 2s infinite linear;
}
@-webkit-keyframes icon-sw-spin-2x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-sw-spin-2x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.icon-sw-spin-lg-r {
  -webkit-animation: icon-sw-spin-lg-r 1.3s infinite linear;
  animation: icon-sw-spin-lg-r 1.3s infinite linear;
}
@-webkit-keyframes icon-sw-spin-lg-r {
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-sw-spin-lg-r {
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.icon-sw-01:before {
  content: "\e600";
}
.icon-sw-save:before {
  content: "\e601";
}
.icon-sw-layer-performer-active:before {
  content: "\e602";
}
.icon-sw-layer-performer:before {
  content: "\e603";
}
.icon-sw-filter-active:before {
  content: "\e604";
}
.icon-sw-filter:before {
  content: "\e605";
}
.icon-sw-import-setdesign:before {
  content: "\e606";
}
.icon-sw-08:before {
  content: "\e607";
}
.icon-sw-09:before {
  content: "\e608";
}
.icon-sw-lock:before {
  content: "\e609";
}
.icon-sw-unlock:before {
  content: "\e60a";
}
.icon-sw-text-notes:before {
  content: "\e60b";
}
.icon-sw-performer:before {
  content: "\e60c";
}
.icon-sw-undo:before {
  content: "\e60d";
}
.icon-sw-layer-scenic-active:before {
  content: "\e60e";
}
.icon-sw-layer-scenic:before {
  content: "\e60f";
}
.icon-sw-setpiece:before {
  content: "\e610";
}
.icon-sw-settings:before {
  content: "\e611";
}
.icon-sw-shapes:before {
  content: "\e612";
}
.icon-sw-share:before {
  content: "\e613";
}
.icon-sw-redo:before {
  content: "\e614";
}
.icon-sw-list:before {
  content: "\e615";
}
.icon-sw-zoom:before {
  content: "\e616";
}
.icon-sw-24:before {
  content: "\e617";
}
.icon-sw-25:before {
  content: "\e618";
}
.icon-sw-26:before {
  content: "\e619";
}
.icon-sw-eye:before {
  content: "\e61a";
}
.icon-sw-28:before {
  content: "\e61b";
}
.icon-sw-29:before {
  content: "\e61c";
}
.icon-sw-30:before {
  content: "\e61d";
}
.icon-sw-collaboration:before {
  content: "\e61e";
}
.icon-sw-import-script:before {
    content: "\e61f";
}
.icon-sw-first:before {
    content: "\e620";
}
.icon-sw-previous:before {
    content: "\e621";
}
.icon-sw-next:before {
    content: "\e622";
}
.icon-sw-last:before {
    content: "\e623";
}
.icon-sw-lightcue:before {
    content: "\e624";
}
.icon-sw-action:before {
    content: "\e625";
}
.icon-sw-39:before {
    content: "\e626";
}
.icon-sw-40:before {
    content: "\e627";
}
.icon-sw-41:before {
    content: "\e628";
}
.icon-sw-42:before {
    content: "\e629";
}
.icon-sw-43:before {
    content: "\e62a";
}
